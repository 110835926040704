<template>
  <div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Attachments
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <button
        @click="newAttachment"
        class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path
            d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4M17 8l-5-5-5 5M12 3v12"
          />
        </svg>
        <span>Upload Attachment</span>
      </button>
    </div>
    <div>
      <p class="text-center" v-if="attachments.length == 0">
        No attachments have been added to this enquiry yet
      </p>
      <div class="mb-5" v-if="attachment && isUploading">
        <p class="font-bold text-lg mb-2">
          Uploading file {{ attachment.name ? attachment.name : "" }}
        </p>
        <progress-bar :progress="uploadProgress" />
      </div>
      <div v-if="attachments.length != 0" class="w-full">
        <table class="table-auto w-full">
          <tbody>
            <template v-for="(attachment, a) in attachments">
              <tr :key="a" class="hover:bg-orange-200 even:bg-gray-100 group">
                <td class="px-4 py-2">
                  <div class="flex items-center">
                    <span class="block">
                      {{ attachment.name || attachment.original_filename }}
                    </span>
                    <span
                      class="block text-gray-600 text-sm ml-2"
                      v-if="attachment.name"
                      >({{ attachment.original_filename }})</span
                    >
                  </div>
                  <span class="block text-sm">
                    {{ attachment.description }}
                  </span>
                </td>
                <td class="px-4 py-2">
                  {{
                    attachment.file_size
                      ? getFileSize(attachment.file_size)
                      : ""
                  }}
                </td>
                <td v-if="attachment.created_at" class="px-4 py-2">
                  {{ $moment(attachment.created_at).format("DD/MM/YYYY") }}
                </td>
                <td class="px-4 py-2">
                  <button
                    @click="deleteAttachment(attachment)"
                    type="button"
                    class="ml-2 float-right bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md p-1"
                  >
                    <svg
                      class="stroke-current h-5 w-5"
                      fill="none"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2M10 11v6M14 11v6"
                      />
                    </svg>
                  </button>
                  <button
                    @click="editAttachment(attachment)"
                    type="button"
                    class="ml-2 float-right bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md p-1"
                  >
                    <svg
                      class="stroke-current h-5 w-5"
                      fill="none"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                      />
                    </svg>
                  </button>
                  <a
                    :href="attachment.access_url"
                    target="_blank"
                    class="ml-2 float-right bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md p-1"
                  >
                    <svg
                      class="stroke-current h-5 w-5"
                      fill="none"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4M7 10l5 5 5-5M12 15V3"
                      />
                    </svg>
                  </a>
                  <button
                    v-if="attachment.content_type == 'application/pdf'"
                    @click="viewAttachment(attachment)"
                    type="button"
                    class="float-right bg-orange-200 border hover:bg-orange-300 border-orange-400 hover:border-orange-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md p-1"
                  >
                    <svg
                      class="stroke-current h-5 w-5"
                      fill="none"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      viewBox="0 0 24 24"
                    >
                      <circle cx="11" cy="11" r="8" />
                      <path d="M21 21l-4.35-4.35" />
                    </svg>
                  </button>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <portal to="overlay-outlet">
        <transition name="slide-bottom">
          <view-attachment
            v-if="viewingAttachmentOpen"
            :attachment="viewingAttachment"
            @close="viewingAttachmentOpen = false"
          />
        </transition>
      </portal>

      <portal to="overlay-outlet">
        <panel
          :showing="addEditAttachmentPanelShowing"
          @close="addEditAttachmentPanelShowing = false"
          :title="
            editingAttachment.attachment_id
              ? 'Edit attachment'
              : 'Add attachment'
          "
        >
          <add-edit-attachment
            :attachment="editingAttachment"
            @complete="closeAttachmentPanel"
          />
        </panel>
      </portal>

      <portal to="overlay-outlet">
        <confirm-alert
          :showingConfirm="confirmAlert.showing"
          :message="confirmAlert.message"
          @complete="confirmAlert.resultFunction"
        />
      </portal>
    </div>
  </div>
</template>

<script>
const Panel = () => import("@/components/Panel.vue");
const ViewAttachment = () =>
  import("@/components/SalesProspects/Edit/ViewAttachment.vue");
const ProgressBar = () => import("@/components/ProgressBar.vue");
const Notification = () => import("@/components/Notification.vue");
const ConfirmAlert = () => import("@/components/ConfirmAlert.vue");
const AddEditAttachment = () =>
  import("@/components/SalesProspects/Edit/AddEditAttachment.vue");

export default {
  name: "EnquiryAttachments",
  components: {
    Panel,
    ViewAttachment,
    ProgressBar,
    Notification,
    ConfirmAlert,
    AddEditAttachment,
  },
  props: {
    enquiry: Object,
  },
  data() {
    return {
      editingAttachment: {},
      addEditAttachmentPanelShowing: false,
      viewingAttachment: {},
      viewingAttachmentOpen: false,
      attachments: [],
      attachment: {},
      isUploading: false,
      uploadProgress: 0,
      confirmAlert: {
        resultFunction: "",
        showing: false,
        message: "",
        title: "",
        data: "",
      },
    };
  },
  async mounted() {
    try {
      let result = await this.SalesProspectService.getProspectAttachments(
        this.enquiry.prospect_id
      );
      this.attachments = result.data;
    } catch (err) {
      this.$breadstick.notify(
        ({ h, onClose }) => {
          return h(
            Notification,
            {
              props: {
                type: "danger",
                title: "Error",
                close: onClose,
              },
            },
            "Unable to load attachments for this enquiry"
          );
        },
        {
          position: "top-right",
        }
      );
    }
  },
  methods: {
    newAttachment: function() {
      this.editingAttachment = {};
      this.addEditAttachmentPanelShowing = true;
    },
    editAttachment: function(attachment) {
      this.editingAttachment = {
        ...attachment,
      };
      this.addEditAttachmentPanelShowing = true;
    },
    closeAttachmentPanel: async function() {
      this.attachment = this.editingAttachment;
      this.addEditAttachmentPanelShowing = false;
      if (this.attachment.attachment_id) {
        await this.updateAttachment();
      } else {
        await this.uploadAttachment();
      }
    },
    updateAttachment: async function() {
      if (this.attachment) {
        try {
          let attachmentData = {
            name: this.attachment.name,
            description: this.attachment.description,
          };

          let updateResult = await this.SalesProspectService.updateProspectAttachment(
            this.enquiry.prospect_id,
            this.attachment.attachment_id,
            attachmentData
          );
          console.log("Result", updateResult);
          let localAttachment = this.$_.find(
            this.attachments,
            (attachment) =>
              attachment.attachment_id == this.attachment.attachment_id
          );
          localAttachment.name = attachmentData.name;
          localAttachment.description = attachmentData.description;
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    title: "Update success",
                    close: onClose,
                  },
                },
                "Your attachment has been successfully updated."
              );
            },
            {
              position: "top-right",
            }
          );
        } catch (error) {
          console.error(error);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "danger",
                    title: "Update Error",
                    close: onClose,
                  },
                },
                "There was a problem updating your attachment, please try again later"
              );
            },
            {
              position: "top-right",
            }
          );
        }
      }
      this.attachment = {};
    },
    uploadAttachment: async function() {
      if (this.attachment) {
        try {
          let attachmentData = {
            filename: this.attachment.file.name,
            content_type: this.attachment.file.type,
            file_size: this.attachment.file.size,
            name: this.attachment.name,
            description: this.attachment.description,
          };

          let createResult = await this.SalesProspectService.createProspectAttachment(
            this.enquiry.prospect_id,
            attachmentData
          );
          let attachmentId = createResult.data.attachment_id;

          // Get attachment metadata with read token
          let metadataResult = await this.SalesProspectService.getProspectAttachmentById(
            attachmentId
          );
          let attMetadata = metadataResult.data;

          attMetadata.is_uploading = true;
          this.attachments.push(attMetadata);
          let arrayIndex = this.$_.findIndex(this.attachments, (a) => {
            return a.attachment_id == attMetadata.attachment_id;
          });

          let accessUrl = createResult.data.access_url;
          let url = new URL(accessUrl);
          let accountUrl = url.origin;
          let sasToken = url.search;

          // Parse container
          let pathParts = url.pathname.split("/").filter((p) => {
            return p && p.trim() !== "";
          });
          let container = pathParts.length > 0 ? pathParts[0] : "";

          // Create filename
          let nonContainerParts = this.$_.drop(pathParts, 1);
          let filename = nonContainerParts.join("/");

          let blobService = AzureStorage.Blob.createBlobServiceWithSas(
            accountUrl,
            sasToken
          );
          let customBlockSize =
            this.attachment.size > 1024 * 1024 * 32
              ? 1024 * 1024 * 4
              : 1024 * 128;
          blobService.singleBlobPutThresholdInBytes = customBlockSize;

          this.isUploading = true;
          let uploadSummary = blobService.createBlockBlobFromBrowserFile(
            container,
            filename,
            this.attachment.file,
            { blockSize: customBlockSize },
            async (error, result, response) => {
              this.isUploading = false;
              if (error) {
                // Upload blob failed
                this.$breadstick.notify(
                  ({ h, onClose }) => {
                    return h(
                      Notification,
                      {
                        props: {
                          type: "danger",
                          title: "Upload Error",
                          close: onClose,
                        },
                      },
                      "There was a problem creating your attachment, please try again later"
                    );
                  },
                  {
                    position: "top-right",
                  }
                );
                this.$delete(this.attachments, arrayIndex);
              } else {
                this.attachments[arrayIndex].is_uploading = false;
                this.$breadstick.notify(
                  ({ h, onClose }) => {
                    return h(
                      Notification,
                      {
                        props: {
                          title: "Attachment Created",
                          close: onClose,
                        },
                      },
                      "Your attachment was created successfully"
                    );
                  },
                  {
                    position: "top-right",
                  }
                );
              }

              this.editingAttachment = {};
              this.uploadProgress = 0;
            }
          );

          uploadSummary.on("progress", () => {
            this.uploadProgress = parseFloat(
              uploadSummary.getCompletePercent()
            );
          });
        } catch (err) {
          console.error(err);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "danger",
                    title: "Upload Error",
                    close: onClose,
                  },
                },
                "There was a problem creating your attachment, please try again later"
              );
            },
            {
              position: "top-right",
            }
          );
        }
      }
      this.attachment = {};
    },
    handleDeleteEvent: async function(result) {
      if (result) {
        this.confirmAlert.showing = false;

        try {
          await this.SalesProspectService.deleteProspectAttachmentById(
            this.confirmAlert.data.attachment_id
          );
          let arrayIndex = this.$_.findIndex(this.attachments, (a) => {
            return a.attachment_id == this.confirmAlert.data.attachment_id;
          });

          this.$delete(this.attachments, arrayIndex);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    title: "Attachment Deleted",
                    close: onClose,
                  },
                },
                "Your attachment was deleted successfully"
              );
            },
            {
              position: "top-right",
            }
          );
        } catch (err) {
          console.error(err);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "danger",
                    title: "Delete Error",
                    close: onClose,
                  },
                },
                "There was a problem deleting your attachment, please try again later"
              );
            },
            {
              position: "top-right",
            }
          );
        }
      }
      this.confirmAlert = {
        resultFunction: "",
        message: "",
        showing: false,
        data: "",
      };
    },
    deleteAttachment: function(attachment) {
      this.confirmAlert = {
        resultFunction: this.handleDeleteEvent,
        message: `Are you sure you want do delete '${attachment.name ||
          attachment.original_filename}'? Cannot be undone`,
        showing: true,
        data: attachment,
      };
    },
    viewAttachment: function(attachment) {
      this.viewingAttachment = attachment;
      this.viewingAttachmentOpen = true;
    },
    getFileSize(sizeBytes) {
      let sizeKb = sizeBytes / 1024;
      return sizeKb > 1023
        ? `${(sizeKb / 1024).toFixed(2)} MB`
        : `${sizeKb.toFixed(2)} KB`;
    },
    // getFileIcon(file) {
    //   console.log("file: ", file);
    //   let icon = "far fa-file";
    //   console.log(file.content_type);
    //   switch (file.content_type) {
    //     case "image/png":
    //     case "image/jpg":
    //     case "image/bmp":
    //       icon = "far fa-image text-success";
    //       break;
    //     case "application/pdf":
    //       icon = "far fa-file-pdf text-danger";
    //       break;
    //     case "word/docx":
    //     case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    //       icon = "far fa-file-word text-primary";
    //       break;
    //     case "application/x-zip-compressed":
    //     case "application/zip":
    //       icon = "far fa-file-archive";
    //       break;
    //   }

    //   return icon;
    // }
  },
};
</script>
